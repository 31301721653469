import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { motion } from 'framer-motion';
import MetaTags from '../../components/MetaTags';
import { theme } from '../../styles/theme';
import Background from '../../components/Background';
import Header from '../../components/Header2';
import HowGroupGiftsWork from '../../sections/occasion/HowGroupGiftsWork';
import CuratedGifts from '../../sections/occasion/CuratedGifts';
import GroupCard from '../../sections/occasion/GroupCard';
import LoveIllume from '../../sections/occasion/LoveIllume';
import JoinOccasions from '../../sections/JoinOccasions';
import Footer from '../../sections/common/FooterWithNav';
import data from '../../sections/occasion/data';
import futureCelebrating from '../../images/occasion/thank-you.png';
import futurePhone from '../../images/occasion/iPhone12.png';
import HeaderSection from '../../sections/occasion/HeaderSection';

const Layout = styled(motion.div)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 24px;
  position: relative;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    -webkit-backface-visibility: hidden;
  }
`;

const OccassionThankYou: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <MetaTags
        title='Occasions | illume'
        description={'Create sincere, memorable thank you ecards and gifts with illume.'}
      />
      <Layout>
        <Background showTopBlob={false} />
        <Header />
        <HeaderSection
          heading={'Sincere Thank You Gifts'}
          paragraphs={[
            'Marie organized a camping trip for her friends. They want to thank her with a group gift from illume.',
            'They pick out a unique gift from illume’s digital marketplace, contribute with personal messages, photos, and more – and then hit send when they’re ready.',
          ]}
          buttonLink={
            'https://web.illumenotes.com/receive?c=2ffb419f-2ae1-4a47-87c9-f8e6149caae0&email=email@tryillume.com'
          }
          images={{
            celebration: {
              image: futureCelebrating,
              label: 'Thank You',
            },
            phone: {
              image: futurePhone,
              label: 'Thank You Mobile',
            },
          }}
        />
        <HowGroupGiftsWork />
        <CuratedGifts gifts={data.gifts} />
        <GroupCard />
        <LoveIllume testimonials={data.testimonials} />
        <JoinOccasions joinMessage={'of saying thanks'} />
        <Footer links={data.links} />
      </Layout>
    </ThemeProvider>
  );
};

export default OccassionThankYou;
